<template>
    <div>
        <b-modal 
        id="TariffAllowanceEdit" 
        title="Изменить данные надбавки" 
        size="s" 
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true" 
        @show="opened"
        >
        <b-row>
            <b-col cols="12">
                <label for="">Наименование</label>
                <b-input v-model="allowance.name" placeholder="Наименования" />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="mt-1">
                <label for="">Цена</label>
                <b-input v-model="allowance.price" placeholder="Цена" />
            </b-col>
        </b-row>
            <b-row>
                <b-col cols="12" class="mt-1">
                    <label for="">Тип</label>
                    <b-form-select :options="allowanceTypes" v-model="allowance.type" />
              </b-col>
            </b-row>  
            <b-row>
                <b-col class="mt-1" cols="12">
                    <label for="">Иконка</label>
                    <b-form-file browse-text="Добавить" accept=".svg" @change="uploadFile" placeholder="Выберите файл или перетащите его сюда...">
                    </b-form-file>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="6">
                    <div class="status">
                        <label for="">Админка</label>
                        <b-form-checkbox v-model="allowance.user_show" checked="true" name="check-button" switch inline>
                            {{ this.allowance.user_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="status">
                        <label for="">Сайт</label>
                        <b-form-checkbox v-model="allowance.site_show"  checked="true" name="check-button" switch inline>
                            {{ this.allowance.site_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="6">
                    <div class="status">
                        <label for="">Водитель</label>
                        <b-form-checkbox v-model="allowance.performer_show" checked="true" name="check-button" switch inline>
                            {{ this.allowance.performer_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="status">
                        <label for="">Клиент</label>
                        <b-form-checkbox v-model="allowance.client_show" checked="true" name="check-button" switch inline>
                            {{ this.allowance.client_show ? 'Активный' : 'Неактивный' }}
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>   
            <b-row class="justify-content-center my-1">
                <img class="width-150" v-if="imageSrc" :src="imageSrc">
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editAllowance">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props: ['id'],
    component: {
        ToastificationContent,
    },
    data(){
        return {
            allowanceTypes: [],
            allowance: {},
            submitButtonDisabled: false,
            imageSrc: null,
            postData: new FormData(),
        }
    },
    methods:{
        uploadFile(event) {
            let file = event.target.files[0];
            this.imageSrc = URL.createObjectURL(file);
            this.postData.append('icon', file);
        },
        editAllowance(){
            this.allowance.client_show = this.allowance.client_show ? 1 : 0
            this.allowance.site_show = this.allowance.site_show ? 1 : 0
            this.allowance.user_show = this.allowance.user_show ? 1 : 0
            this.allowance.performer_show = this.allowance.performer_show ? 1 : 0
            // this.postData.append('name', this.allowance.name);
            // this.postData.append('price', this.allowance.price);
            // this.postData.append('type', this.allowance.type);
            // this.postData.append('client_show', this.allowance.client_show);
            // this.postData.append('site_show', this.allowance.site_show);
            // this.postData.append('user_show', this.allowance.user_show);
            // this.postData.append('performer_show', this.allowance.performer_show);
            this.submitButtonDisabled = true
            this.$http
                .post(`${this.$route.name}/${this.id}`, this.allowance)
                .then(res=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.btnCancel()
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get(`${this.$route.name}/${this.id}/edit`)
                .then(res => {
                    this.allowance = res.data
                    this.allowance.client_show = this.allowance.client_show == 1 ? true : false
                    this.allowance.site_show = this.allowance.site_show == 1 ? true : false
                    this.allowance.user_show = this.allowance.user_show == 1 ? true : false
                    this.allowance.performer_show = this.allowance.performer_show == 1 ? true : false
                    this.imageSrc = res.data.icon
            })
            this.$http.get('tariff/allowances/data')
                .then(response => {
                    this.allowanceTypes = response.data.types;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        btnCancel(){
            this.$bvModal.hide('allowanceEdit')
        }
    },
}
</script>

<style scoped>
.status {
    display: flex;
    flex-direction: column;
}
</style>
